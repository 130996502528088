@use "sass:math";

$inset: 1rem;
$min-height: 12rem;


.dialog-modal-fade {
  position: fixed;
  z-index: $z-index-f;
  background-color: rgb(0 0 0 / 70%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}



.dialog {
  background-color: $white;
  min-height: $min-height;
  display: block;
  inset: 0;
  margin: auto;
  border: 1px solid $gray-light;
  border-radius: $border-radius;
  padding: $padding * 2;
  box-shadow:
    0 3px 3px -2px rgb(0 0 0 / 20%),
    0 3px 4px 0 rgb(0 0 0 / 14%),
    0 1px 8px 0 rgb(0 0 0 / 12%);
    color: $black;
    

    

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
    width: $touch-target-size;
    height: $touch-target-size;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-family: monospace;
  }

  &--placement {
    &--below-top{
      /* using calc to allow comparison between viewport height and min-height(rem) */
      margin-top: calc((50vh - $min-height) / 2); 
        }  
        
    &--top {
      /* using calc to allow comparison between viewport height and min-height(rem) */
      margin-top: calc((17vh - $min-height) / 2);
    }
  
  }

  &--background-color {
    &--transparent {
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }
  }

  &--width {
  &--auto{
       min-width: auto ;
       max-width: auto; 
    }

    &--normal{
        max-width: $content-item-min-width-small;
         min-width: $content-item-min-width;
         min-height: $min-height;

    }

    &--wider {
        min-width: $content-item-min-width-large;
        max-width: $content-item-min-width-large;
        
        @media screen and (min-width: $break-small) {
            min-width: $content-item-max-width-large;
            max-width: $content-item-max-width-large;
        }
    }
}
}
