.swiper {
  display: block;

  &--full-width {
    margin-left: -$left-and-right-inset; /* swiper skal spanne full bredde på mobil */
    margin-right: -$left-and-right-inset;
    max-width: calc(100% + $left-and-right-inset * 2);

    @media screen and (min-width: $break-large) {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
    }
  }

  &__arrow-button {
    width: auto;
    height: auto; 
    background-color: #fffc;
    opacity: 1;
    border-radius: 50%;
    padding: 0.4rem;
    box-shadow: $box-shadow;
    position: absolute;
    z-index: $z-index-a;

    &--prev {
      width: auto !important;
      height: auto !important;
      background-color: #fffc!important;
      opacity: 1 !important;
      border-radius: 50% !important;
      padding: 0.4rem !important;
      box-shadow: $box-shadow;
      left: 0.4rem !important;
    }

    &--next {
      width: auto !important;
      height: auto !important;
      background-color: #fffc!important;
      opacity: 1 !important;
      border-radius: 50% !important;
      padding: 0.4rem !important;
      box-shadow: $box-shadow;
      right: 0.4rem !important;
    }

    &--on-top {
      top: 0 !important;
    }

    &:disabled {
   display:none;
    }
  }

  li.splide__slide {
  padding: 0 0 $padding;
  cursor: zoom-in;
}
  
}

.splide__pagination {
  bottom: -2.5rem !important;
  color: $blue-bg-color !important;

  li {
    padding: 0 $step;
  }
}

.splide__pagination__page {
  background: $blue-bg-color !important;
}

.modal{
  display: none;
  cursor: auto ;
  max-height: 100vh;

  @media screen and (min-width: $break-medium) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    overflow: hidden;
    padding: 0;
    position: relative;
    box-sizing: border-box;
  }

  .splide__slide {
    padding: 0 0 $padding;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      max-width: 100%;
      max-height: 90vh;
      height: auto;
      width: auto;
      object-fit: contain;
      display: block;
      padding: 0 !important;
    }

  }
  
  &__swiper .splide__arrows {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100rem;
  }
}