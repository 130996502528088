/* variables-filen må ligge øverst */
@import "./variables.module";
@import "./card.module";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

/** 
 * setting html font-size to 62.5% will make it easier to convert (in yo' head) from px to rem: 
 * 1rem = 10px > 10px/16px (16px is usually default browser font size) = 62.5% 
 */
html {
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: sans-serif; /* fallback if/when next/font doesn't load */
}

body {
  position: relative;
  width: 100%;
  font-size: $font-size;
  color: $black;
  min-height: 100vh; /* fallback for browsers that do not support custom properties */
  min-height: calc(var(--vh, 1vh) * 100);
  line-height: $line-height;
}

a {
  text-decoration: underline;
  color: $black;
}

hr {
  width: 100%;
  border-bottom: 1px solid #ddd;
  border-top: none;
  border-right: none;
  border-left: none;
}

header {
  position: sticky;
  top: 0;
  background-color: $white;
  z-index:  $z-index-c;
  height: $header-height;
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%);

  nav {
    width: 100%;
  }
}

footer {
  min-height: $footer-min-height;
  background-color: $blue-bg-color;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: $white;
  }

  hr {
    border-bottom: 1px solid #5b9ab4;
  }
}

main {
  min-height: calc(var(--vh, 1vh) * 100 - $header-height - $footer-min-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

ul,
ol {
  margin-left: 2rem;
}

ul,
ul ul {
  list-style-type: disc;
  margin-bottom: 2rem;
}

li {
  text-align: left;
  color: $black;
  padding-left: $step;
}

button {
  background-color: transparent;
  border: none;
  appearance: none;
  cursor: pointer;
  font-family: inherit;
  font-size: $font-size;
  line-height: $line-height;
  color: $black;
}

p {
  margin-bottom: 1.2rem;
}

blockquote {
  border-left: 4px solid $gray-lightest;
  font-style: italic;
  padding: $padding $padding * 2;

  > * {
    margin: 0;
  }
}

.fresnel-container{
  height: 100%;
  width: 100%;
}

select, option {
  font-family: inherit, sans-serif;
}